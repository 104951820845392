/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.example-container {
  width: 500px;
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.example-sidenav-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.example-sidenav {
  padding: 20px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.purple {
  color: purple;
}

.yellow {
  color: yellow;
}

.grey {
  color: gray;
}

.blue {
  color: blue;
}

.mat-radio-label-content {
  display: flex !important;
  align-items: center !important;
}

a {
  cursor: pointer !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.blink-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
